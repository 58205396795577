import {GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import { ApiConstants, ServerURL } from "./ApiConstants";

const GetTriviaPlayEligible = async (val: any) => {
    let apiResponse;
    if (ApiConstants.RUN_PROD_SERVER || process.env.NODE_ENV !== "development") {
        apiResponse = await GetRequest(ServerURL("/trivia/eligible"), val)
    } else {
        apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
        return apiResponse.body;
    } else {
        return apiResponse.error
    }
};

function dummyPlans() {
    return {"data":{"game_id":1316},"success":true,"error":null}
}

export default GetTriviaPlayEligible
