import openGift from '../../Common/Images/giftOpen.png'
import QuizAnswer from "../../Common/Models/QuizAnswer";
import isMobile from "is-mobile";

type QuizProgressBarProps = {
  max: number,
  current: number,
  answers: QuizAnswer[]
}

const QuizProgressBar = (
  {
    max,
    current,
    answers
  }: QuizProgressBarProps
) => {

  return(
    <div className={`box ${isMobile() ? "mt-3 px-2" : "mt-5"}`}>
      <div className={"columns is-centered is-variable is-1-mobile is-mobile is-multiline"}>
        {
          [...Array(max).keys()].map((a ) => {
            let cKey = a+1
            let b = answers[cKey]
            let status = b ? (b.correct ? "correct" : "incorrect") : (cKey===current ? "current" : "wait")
            return(
              <div
                className={"column is-narrow-mobile"}
                key={cKey}
              >
                <div
                  className={`option-circle ${status}`}
                >
                  <span>
                    {cKey}
                  </span>
                </div>
              </div>
            )
          })
        }
        <div
          key={"gift"}
          className={"column is-narrow"}
        >
          <img
            src={openGift}
            alt={"Gift"}
            className={"imggift"}
          />
        </div>
      </div>
    </div>
  )
}
export default QuizProgressBar