import QuizOptions from "../../Common/Models/QuizOptions";
import QuizQuestionModel from '../../Common/Models/QuizQuestion'
import {useEffect, useState} from "react";
import isMobile from "is-mobile";

type QuizQuestionProps = {
  question: QuizQuestionModel,
  options: QuizOptions[],
  answer: Function,
  hold: boolean
}

const questionNumberMapping = ["A", "B", "C", "D", "E"]
const TRIVIA_QUESTION_PRETEXT_CLASSNAME = "ib-trivia-option-pretext p-2 mx-1"
const TRIVIA_OPTION_CLASSNAME = `column is-half ${!isMobile() ? "my-2" : "py-1"}`
const TRIVIA_OPTION_CONTENT_CLASSNAME = `${isMobile()?"p-2":"p-3"} ib-trivia-option is-size-6-tablet has-text-weight-medium my-2`

const QuizQuestion = (
  {
    question,
    options,
    answer,
    hold
  }: QuizQuestionProps
) => {
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState<string | null>(null)

  useEffect(() => {
    //console.log("hold", hold, question.id)
    setShow(hold)
  }, [hold])

  const select = (key: string) => {
    let ca = question.correct.toLowerCase() === key.toLowerCase()
    setShow(true)
    setSelected(key)
    answer(question.id, ca, {
      questionTitle: question.title,
      answered: key,
      skipped: false,
      correct: ca,
      questionKey: question.id
    })
  }

  const answerOptions =  <>
        {options.map((o, i) => (
          <div
            key={o.answer}
            className={TRIVIA_OPTION_CLASSNAME}
            onClick={() => select(o.answer)}
          >
            <div
              className={TRIVIA_OPTION_CONTENT_CLASSNAME}
            >
              <p
                className={TRIVIA_QUESTION_PRETEXT_CLASSNAME}
              >
                {questionNumberMapping[i%questionNumberMapping.length]}
              </p>
              <p className={"ib-trivia-option-text is-size-6-mobile"}>
                {o.answer}
              </p>
            </div>
          </div>
        ))}
      </>

  const disabledOptions = <>
    {options.map((o, i) => (
      <div
        key={o.answer + "disabled"}
        className={`${TRIVIA_OPTION_CLASSNAME}`}
      >
        <div
          className={`${TRIVIA_OPTION_CONTENT_CLASSNAME}
          disabled-options
            ${o.answer.toLowerCase()===question.correct.toLowerCase() ? "correct" : ""}
            ${selected?.toLowerCase() === o.answer.toLowerCase() ? "wrong" : ""}
          }`}
        >
          <p
            className={TRIVIA_QUESTION_PRETEXT_CLASSNAME}
          >
            {questionNumberMapping[i%questionNumberMapping.length]}
          </p>
          <p className={"ib-trivia-option-text"}>
            {o.answer}
          </p>
        </div>
      </div>
    ))}
  </>

  return(
    <div
      className={"column is-9"}
    >
      <h4
        className={"has-text-centered is-size-3-tablet has-text-weight-bold"}
      >
        {question.title}
      </h4>
      <div
        className={`${isMobile()?"my-4":"my-6"}`}
        style={{
          borderTop: "1px solid black"
        }}
      />
      <div className={"columns is-variable is-7 is-2-mobile is-multiline"}>
        {show ? disabledOptions : answerOptions}
      </div>
    </div>
  )
}

export default QuizQuestion