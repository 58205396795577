import QuizRewardItem from "../../Common/Models/QuizRewardItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRibbon, faAward, faGift } from '@fortawesome/free-solid-svg-icons'
import r1 from '../../Common/Images/ribbon/ribbon1.png'
import r2 from '../../Common/Images/ribbon/ribbon2.png'
import r3 from '../../Common/Images/ribbon/ribbon3.png'
import r4 from '../../Common/Images/ribbon/ribbon4.png'
import RewardItem from "./RewardItem";

type RewardsProps = {
  rewardItems: QuizRewardItem[]
}

const Rewards = (
  {
    rewardItems
  }: RewardsProps
) => {

  return(
    <div
      className={"card my-5"}
      style={{
        borderRadius: "20.5251px",
        border: "3.42085px solid #F4F6F7",
      }}
    >
      <div
        className={"card-content ibcardp"}
      >
        <h4
          className={"title mt-3 is-size-2-desktop is-size-3-tablet is-size-4-mobile"}
        >
          Prizes ✨
        </h4>
        <div
          className={"columns is-justify-content-space-around is-variable is-8-desktop is-multiline is-align-items-stretch"}
        >
          {rewardItems.map((a) => (
            <RewardItem
              item={a}
              key={a.id}
            />
          ))}
        </div>
      </div>
    </div>
  )
}


// <FontAwesomeIcon
//   icon={faAward}
//   className={iconClassName}
//   style={iconStyles(a.rank)}
// />

export default Rewards